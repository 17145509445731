import React from "react"

import { Helmet } from "react-helmet"

import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ description, keywords, title, image, url }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          image
        }
      }
    }
  `)

  const metaDescription = description || data.site.siteMetadata.description
  const metaTitle = title || data.site.siteMetadata.title
  const metaUrl = url || data.site.siteMetadata.url
  const metaImage = image || data.site.siteMetadata.image
  const metaKeywords = keywords || [
    "freguesia do touro",
    "junta de freguesia do touro",
  ]

  console.log("metaDescription", metaDescription)
  return (
    <Helmet
      title={title}
      meta={[
        {
          name: "description",
          content: metaDescription,
        },
        {
          name: "og:title",
          content: metaTitle,
        },
        {
          name: "og:description",
          content: metaDescription,
        },
        {
          name: "og:type",
          content: "website",
        },
        {
          name: "og:image",
          content: metaImage,
        },
        {
          name: "og:url",
          content: metaUrl,
        },
      ].concat(
        metaKeywords && metaKeywords.length > 0
          ? {
              name: "keywords",
              content: metaKeywords.join(`, `),
            }
          : []
      )}
    >
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon.png" />
    </Helmet>
  )
}
export default SEO
