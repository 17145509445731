export const pages = [
  {
    name: "Freguesia",
    path: "/freguesia/",
  },
  {
    name: "Notícias",
    path: "/noticias/",
  },
  {
    name: "Rota do Caldeirão",
    path: "/rota-caldeirao/",
  },
  {
    name: "Costumes",
    path: "/costumes/",
  },
  {
    name: "Institucional",
    path: "/institucional/",
  },
  {
    name: "Contactos",
    path: "/contactos/",
  },
]
