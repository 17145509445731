import React, { useState } from "react"
import { Link } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import classNames from "classnames"

import { FaBars } from "react-icons/fa"
import Logo from "../../assets/layout/logo.svg"
import headerStyles from "./index.module.scss"

import NavList from "../NavList"

const Header = () => {
  const breakpoints = useBreakpoint()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const mobileNavListClass = classNames({
    [headerStyles.mobileNavListContainer]: true,
    [headerStyles.mobileNavListContainerActive]: mobileMenuOpen,
  })

  return (
    <header className={headerStyles.header}>
      <Link className={headerStyles.title} to="/">
        <Logo />
      </Link>
      {breakpoints.header && (
        <div className={headerStyles.mobileNav}>
          <FaBars
            size="1.5rem"
            color="#2595D2"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          />
          <div className={mobileNavListClass}>
            <NavList header vertical />
          </div>
        </div>
      )}
      {!breakpoints.header && <NavList header />}
    </header>
  )
}

export default Header
