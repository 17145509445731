import React from "react"

import {
  FaFacebookSquare,
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhone,
} from "react-icons/fa"
import Logo from "../../assets/layout/logo.svg"
import footerStyles from "./index.module.scss"

import NavList from "../NavList"

const Footer = () => {
  return (
    <footer className={footerStyles.footer}>
      <div className={footerStyles.container}>
        <div className={footerStyles.brand}>
          <Logo />
          <p>
            Touro, terra do principio, de herança e cultura, arqueologia rural a
            olhar futuro.
          </p>
          <div className={footerStyles.facebook}>
            <FaFacebookSquare size="2.5rem" color="#00104A" />
            <a
              href="https://www.facebook.com/freguesiadetouro"
              target="_blank"
              rel="noopener noreferrer"
            >
              /freguesiadetouro
            </a>
          </div>
        </div>
        <div className={footerStyles.navlist}>
          <NavList vertical />
        </div>
        <div className={footerStyles.contactItems}>
          <div className={footerStyles.contactItem}>
            <FaMapMarkerAlt size="2.5rem" color="#00104A" />
            <p>R. Cónego Alfredo Ferreira Morais Martins</p>
          </div>
          <div className={footerStyles.contactItem}>
            <FaEnvelope size="2.5rem" color="#00104A" />
            <a href="mailto:freguesiadotouro@hotmail.com">
              freguesiadotouro@hotmail.com
            </a>
          </div>
          <div className={footerStyles.contactItem}>
            <FaPhone size="2.5rem" color="#00104A" />
            <p>232 602 304</p>
          </div>
        </div>
      </div>
      <div className={footerStyles.copyright}>
        <p>{`Copyright © ${new Date().getFullYear()} - Freguesia de Touro`}</p>
      </div>
    </footer>
  )
}

export default Footer
