import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import styles from "./index.module.scss"

import Button from "../Button"

import { pages } from "../../utils"

const NavList = ({ header, vertical }) => {
  const navListClass = classNames({
    [styles.navList]: true,
    [styles.mobileNavList]: vertical,
  })

  return (
    <nav>
      <ul className={navListClass}>
        {pages.map((page, index) => {
          return (
            <li key={`listItem-${index}`}>
              <Link
                className={styles.navItem}
                to={page.path}
                activeStyle={{
                  textDecoration: "underline",
                }}
              >
                {header && page.name === "Contactos" ? (
                  <Button>{page.name}</Button>
                ) : (
                  page.name
                )}
              </Link>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default NavList
