import React from "react"
import { Helmet } from "react-helmet"

import Header from "../Header"
import Footer from "../Footer"
import SEO from "../SEO"

import "../../styles/normalize.scss"
import "../../styles/globalStyles.scss"

import layoutStyles from "./index.module.scss"

const Layout = ({ children }) => {
  return (
    <>
      <SEO
        title="Freguesia do Touro"
        description="Website institucional da Junta de Freguesia do Touro"
      />
      <div className={layoutStyles.container}>
        <div className={layoutStyles.content}>
          <Header />
          {children}
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Layout
