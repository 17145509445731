import React from "react"

import buttonStyles from "./index.module.scss"

const Button = ({ children, ...rest }) => {
  return (
    <button className={buttonStyles.button} {...rest}>
      {children}
    </button>
  )
}

export default Button
